import React from "react"
import Header from "../components/header"

import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import { Player } from "video-react"

import "video-react/dist/video-react.css"

import video from "../images/video.mp4"
import poster from "../images/poster.png"

import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import ym from "react-yandex-metrika"

class Info extends React.Component {
  componentDidMount() {
    ym("hit", "/info")
  }

  render() {
    return (
      <div>

        <Helmet>
          <title>Информация - Исполиния</title>
          <meta name="description"
                content="RBM Каталог продукции. Прайс-лист ИСПОЛИНИЯ. Сертификат официального дилера RBM. IBOX – центр исследований и разработок RBM, предназначенный для практического обучения в санитарно-технической отрасли." />

          <meta property="og:title" content="Информация" />
          <meta property="og:description"
                content="RBM Каталог продукции. Прайс-лист ИСПОЛИНИЯ. Сертификат официального дилера RBM. IBOX – центр исследований и разработок RBM, предназначенный для практического обучения в санитарно-технической отрасли." />
        </Helmet>

        <Header />
        <main>
          <div className={"first"}>
            <Container>
              <div className="row m-auto">
                <div className="col-md m-auto text">
                  <h2>КАТАЛОГ ПРОДУКЦИИ</h2>
                  <p>Ассортимент компонентов RBM очень широк и насчитывает тысячи артикулов, относящихся к различным
                    категориям инженерной сантехники.
                    <br />Для удобства работы с информацией Вы можете выбрать:</p>
                  <div className="mb-5">
                    <div className="buttons">
                      <Link
                        to="../catalog-small.pdf" download>
                        <button className="col-md">Смотреть популярное</button>
                      </Link>
                      <Link
                        to="../catalog-full.pdf" download>
                        <button className="col-md blue">Каталог полностью</button>
                      </Link>
                      <Link
                        to="../prices" download>
                        <button className="col-md">Базовый прайс-лист</button>
                      </Link>
                      <Link
                        to="../new-products-rbm.pdf" download>
                        <button className="col-md blue">Новинки RBM 2021</button>
                      </Link>
                    </div>

                  </div>
                </div>
                <div className="col-sm">
                  <Link to="../catalog-small.pdf"><StaticImage placeholder="blurred" className="col-sm m-auto"
                                                       src="../images/catalogue.png"
                                                       alt="Изображение каталога продукции RBM"
                  /></Link>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div className="row m-auto mb-md-5">
              <div id="old-certificate" className="col-md">
                <Link to="../certificate.jpg">
                  <StaticImage placeholder="blurred" className="col-md m-auto"
                               src="../images/certificate.jpg"
                               alt="Изображение прошлогоднего сертификата официального дилера"
                  />
                </Link>
              </div>
              <div className="col-md">
                <Link to="../certificate-2022.pdf">
                  <StaticImage placeholder="blurred" className="col-md m-auto"
                               src="../images/certificate-2022.jpg"
                               alt="Изображение сертификата официального дилера"
                  />
                </Link>
              </div>
              <div className="col-sm m-auto">
                <p><span className="blue">Официальный статус</span> компании ИСПОЛИНИЯ подтвержден
                  соответствующим документом. Приобретая продукцию RBM у официального дилера, Вы всегда можете быть
                  уверены в её оригинальном происхождении, а также в полной легальности всех импортных поставок на
                  территорию России.</p>
                <p>Продукция RBM успешно продается во многих странах мира, прошла все необходимые испытания и
                  сертифицирована по нескольким стандартам.
                </p><p>
                На территории Российской Федерации высокое качество товаров
                RBM подтверждено Декларацией о соответствии ЕАС.</p>

                <div className="buttons declaration">
                  <Link to="../declarations">
                    <button className="col-md">Декларации соответствия <span className={"eac"}>EAC</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <h2>RBM IBOX</h2>
            <p>Разработать качественные компоненты для инженерных систем и наладить их серийное производство – сложная
              задача. Но даже этого будет недостаточно для коммерческого успеха продукции на рынке в современных
              условиях
              высокой конкуренции. Необходимо полноценно донести важную информацию о преимуществах до профессионалов
              отрасли: монтажников, проектировщиков, дизайнеров и других специалистов, а также сформировать в этой
              требовательной среде устойчивое положительное отношение к бренду. Для эффективной реализации этой
              непростой
              задачи компания RBM создала специализированное подразделение IBOX. Предлагаем Вашему вниманию
              ознакомительное видео о данном центре.</p>
          </Container>

          <div className="row m-auto">
            <Player poster={poster} src={video} />
          </div>


        </main>

        <Footer />
      </div>
    )
  }
}

export default Info